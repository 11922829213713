const featureList = [
  {
    imgUrl: "/assets/images/SearchHero.svg",
    text: "Access Content Anywhere, Anytime",
    color: "#063755",
  },
  {
    imgUrl: "/assets/images/salesman.svg",
    text: `Secure and Reliable`,
    color: "#DB2BBB",
  },
  {
    imgUrl: "/assets/images/bookHero.svg",
    text: "Interactive Learning Experience",
    color: "#DBA32B",
  },
  {
    imgUrl: "/assets/images/CertiHero.svg",
    text: "Globally Recognized Certifications",
    color: "#2BDB4B",
  },
];

export { featureList };
