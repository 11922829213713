import { Typography } from "@mui/material";
import React from "react";

export function HeroText1({ text }) {
  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: "60px",
        fontWeight: "700",
        color: "#3A3A3A",
        textShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
      }}
    >
      {text}
    </Typography>
  );
}

export function HeroText15({ text }) {
  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: "48px",
        fontWeight: "600",
        color: "#3A3A3A",
        textShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
      }}
    >
      {text}
    </Typography>
  );
}

export function Heading2({ text }) {
  return (
    <Typography
      variant="h2"
      sx={{ fontSize: "36px", fontWeight: "600", color: "#101828" }}
    >
      {text}
    </Typography>
  );
}

export function Heading3({ text }) {
  return (
    <Typography
      variant="h2"
      sx={{ fontSize: "26px", fontWeight: "600", color: "#101828", my: "5px" }}
    >
      {text}
    </Typography>
  );
}

export function Heading4({ text }) {
  return (
    <Typography
      variant="h3"
      sx={{ fontSize: "20px", fontWeight: "400", color: "#000" }}
    >
      {text}
    </Typography>
  );
}

export function Subtitle1({ text }) {
  return (
    <Typography
      variant="body1"
      sx={{ fontSize: "20px", fontWeight: "400", color: "#667085", my: "5px" }}
    >
      {text}
    </Typography>
  );
}

export function Subtitle2({ text, colorCustom }) {
  return (
    <Typography
      variant="body1"
      sx={{
        fontSize: "14px",
        fontWeight: "500",
        color: colorCustom ? colorCustom : "#344054",
      }}
    >
      {text}
    </Typography>
  );
}
