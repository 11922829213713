import Image from "next/image";
import { Box, Grid, Typography } from "@mui/material";

import styles from "../../app/page.module.css";
import { PrimaryBorderButton } from "../Common/CustomButtons";
import FeaturesCard from "../Common/FeaturesCard";
import { featureList } from "./../../utils/FeaturesData";
import useWebsiteInfoStore from "@/utils/store/WebsiteInfoStore";
import ViewTextBox from "../Common/ViewTextBox";
import { Heading2 } from "../Common/HeadingText";
export default function HeroSection() {
  const { websiteInfoData } = useWebsiteInfoStore();

  return (
    <Box>
      <Grid container>
        <Grid lg={6} item={true}>
          {/* <Typography
            variant="h5"
            sx={{
              mt: { lg: "100px", xs: "0px" },
              color: "#DB2BBB",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            Learn Today And
          </Typography> */}
          {websiteInfoData ? (
            <Box>
              <Typography
                variant="h1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: { xs: "40px", lg: "60px" },
                  fontWeight: 700,
                  textShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
                  marginBottom: "30px",
                  mt: { xs: 10 },
                }}
              >
                {websiteInfoData.websiteTitle}
               
                {/* <Typography
                  variant="body1"
                  sx={{
                    color: "#3A3A3A",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "left",
                    mb: "15px",
                    mr: { lg: "50px" },
                  }}
                >
                  {websiteInfoData.websiteHomeDescription1}
                </Typography> */}
                {/* <Typography
               variant="span"
               sx={{
                 background: "#FF9B26",
                 borderRadius: "5px",
                 ml: "10px",
                 pr: "10px",
                 px: "5px",
               }}
             >
               future
             </Typography> */}
              </Typography>
              <Heading2
        text={
          websiteInfoData
            && websiteInfoData?.websiteHomeHeading1
            
        }
      />
              <Box>
                  <ViewTextBox
                  value={websiteInfoData?.websiteHomeDescription1 && JSON.parse(websiteInfoData?.websiteHomeDescription1)}
                  ></ViewTextBox>
                </Box>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="h1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: { xs: "40px", lg: "60px" },
                  fontWeight: 700,
                  textShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
                  marginBottom: "30px",
                  mt: { xs: 10 },
                }}
              >
                e Brainee Learning Management System
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: "18px",
                  fontWeight: 600,
                  mb: "15px",
                  mr: { lg: "40px" },
                }}
              >
                "Empowering Organisations with Flexible and Innovative LMS Solutions."
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: "18px",
                  fontWeight: 500,
                  textAlign: "justify",
                  mb: "15px",
                  mr: { lg: "50px" },
                }}
              >
                <br></br>At e Brainee, we specialise in transforming organisations' approaches to training and development. Our innovative Learning Management System (LMS) empowers businesses to streamline operations, enhance team productivity, and achieve their core objectives. With a strong focus on product innovation and exceptional service, e Brainee is your trusted partner for scalable and efficient LMS solutions.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#3A3A3A",
                  fontSize: "18px",
                  fontWeight: 600,
                  mb: "15px",
                  mr: { lg: "40px" },
                }}
              >
                Discover the Benefits of e Brainee's Flexible and Scalable Learning Management System
              </Typography>
              <ul>
              <Typography
                variant="body1"
                sx={{
                  color: "#4c4e52",
                  fontSize: "18px",

                  textAlign: "justify",
                  mb: "15px",
                  mr: { lg: "50px" },
                }}
              >
                <li> Boost productivity with a user-friendly Mobile platform designed to streamline training and development.</li>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4c4e52",
                  fontSize: "18px",

                  textAlign: "left",
                  mb: "15px",
                  mr: { lg: "50px" },
                }}
              >
                <li> Flexible LMS solutions tailored for education, corporate environments, and nonprofit organisations.</li>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#4c4e52",
                  fontSize: "18px",

                  textAlign: "left",
                  mb: "15px",
                  mr: { lg: "50px" },
                }}
              >
                <li> Expert IT support for the development, management, and ongoing maintenance of your LMS platform. </li>
              </Typography>
              </ul>
            </Box>
          )}
          {/* <PrimaryBorderButton btnText="Learn more" /> */}
        </Grid>
        <Grid
          lg={6}
          item={true}
          sx={{
            marginTop: 5,

            display: { xs: "none", lg: "block" },
          }}
        >
          <Box>
            {/* <Image
              src="/assets/images/offer.jpeg"
              alt="ebrainee"
              width={90}
              height={90}
              style={{ width: "80%", height: "100%", marginLeft: 50 }}
            /> */}
          </Box>
          <Image
            src="/assets/images/HeaderSection.svg"
            alt="ebrainee"
            width={100}
            height={700}
            style={{ width: "100%", maxHeight: "100%" }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {/* <Box
          sx={{
            position: "absolute",
            top: "800px",
            left: "500px",
            zIndex: 1,
          }}
        >
          <Image
            src="/assets/images/dotedcureveline1.svg"
            alt="ebrainee stars"
            className={styles.dotCurve}
            width={100}
            height={100}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box> */}
        {websiteInfoData ? <></> : 
        <Typography
        variant="h1"
        sx={{
          color: "#3A3A3A",
          position: "relative",
          mt: { lg: "40px", xs: "80px" },
          fontSize: "48px",
          fontWeight: 700,
          textShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
          my: "30px",
          textAlign: "center",
          zIndex: 2,
        }}
      >
        What Makes e Brainee LMS Unique?
      </Typography>

        }
        
        <Box>
          <Grid container>
            {featureList.map((feature, index) => {
              return <FeaturesCard key={index} feature={feature} />;
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
